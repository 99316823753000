<template>
  <!-- 批量配置页面 -->
  <div class="batch-userPermission" v-loading="confirmLoading">
    <div class="authorize-top f-c">
      <div class="f-l authorize-top-btn" @click="goBack">
        <img
          src="@/assets/images/icon/navtop_back.png"
          class="authorize-top-btnicon"
        />
        <span>{{ $t("el.common.back") }}</span>
      </div>
      <div class="f-l authorize-top-title">
        {{ $t("el.home.teachingManage") }} >
        {{ $t("el.infrastructure.TeacherBatchAuthorization") }}
      </div>
    </div>
    <div class="batch-userPermission-main">
      <div class="batch-userPermission-main-item">
        <div class="f-c">
          <div
            class="f-c f-l batch-userPermission-main-item-item"
            style="padding-top: 20px"
          >
            <div class="batch-userPermission-main-item-item-label">
              {{ $t("el.infrastructure.selected") }}
            </div>
            <div
              class="batch-userPermission-main-item-item-text"
              style="margin-left: 10px"
            >
              {{ selectUsers }} {{ $t("el.courseDetails.total") }}
              {{ selectUsers && selectUsers.split(",").length }}
              {{ $t("el.infrastructure.numberPeople") }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          class="batch-userPermission-main-item-title"
          style="margin-top: 12px"
        >
          功能权限
        </div>
        <div class="" style="display: flex; align-items: center">
          <div
            class="authorize-content-item-item-label required"
            style="color: #7f7f7f; font-size: 14px"
          >
            {{ $t("el.common.FunctionalRole") }}{{ $t("el.common.colon") }}
          </div>

          <div class="authorize-content-item-item">
            <div class="authorize-content-item-item-specific f-c">
              <el-select
                v-model="formData.userFunctionAuthDto.roleIds"
                :placeholder="$t('el.common.pleaseEnter')"
                multiple
                filterable
              >
                <el-option
                  v-for="item in roleOptions.filter((i) => !i.isSuper)"
                  :key="item.roleId"
                  :value="item.roleId"
                  :label="item.roleName"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div
          class=""
          style="display: flex; align-items: center; margin-top: 12px"
        >
          <div
            class="authorize-content-item-item-label"
            style="color: #7f7f7f; font-size: 14px"
          >
            {{ $t("el.common.resourceUse") }}{{ $t("el.common.colon") }}
          </div>
          <div
            class="userPermission-main-item-item-radio-item"
            style="margin-left: 13px"
          >
            <el-checkbox
              v-model="formData.userFunctionAuthDto.upload"
              :true-label="0"
              :false-label="1"
              >{{
                $t("el.infrastructure.allowUploadPersonalResources")
              }}</el-checkbox
            >
            <el-checkbox
              v-model="formData.userFunctionAuthDto.download"
              :true-label="0"
              :false-label="1"
              >{{ $t("el.infrastructure.allowDownloadResources") }}</el-checkbox
            >
            <el-checkbox
              v-model="formData.userFunctionAuthDto.copy"
              :true-label="0"
              :false-label="1"
              >{{ $t("el.infrastructure.allowCopyResources") }}</el-checkbox
            >
          </div>
        </div>
      </div>
      <div v-loading="allLoading" class="batch-userPermission-main-item">
        <div class="batch-userPermission-main-item-title">
          {{ $t("el.infrastructure.coursePermissions") }}
          <span
            >({{ $t("el.courseList.SelectedCourses") + $t("el.common.colon")
            }}{{ chosedAuthCourseIdsLength }})</span
          >
        </div>
        <course-permission
          ref="coursePermission"
          @setAllLoading="(loading) => (allLoading = loading)"
          :staffCode="formData.userStaffCode"
          :type="'add'"
          :authCourseIds="formData.authCourseIds"
          :getGradeListData="getAddGradeListData"
          @get-max-authorize-date="getMaxAuthorizeDate"
          style="height: 500px"
        ></course-permission>
      </div>
    </div>
    <div class="batch-userPermission-btns">
      <el-button
        style="margin-right: 15px"
        type="primary"
        @click="hanndleUpdateBatch"
        >{{ $t("el.infrastructure.BatchAdd") }}</el-button
      >
      <el-button
        class="batch-userPermission-btns-cancel"
        style="margin-left: 15px"
        @click="batchRemove"
        >{{ $t("el.infrastructure.BatchDel") }}</el-button
      >
    </div>
    <c-dialog
      ref="dialogEditCourse"
      :visible="showEditExpiresDialog"
      :optionsData="optionsEditData"
      @close-dialog="closeEditExpiresDialog"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :hideForm="true"
    >
      <div slot="customView" class="custom-edit-content">
        <btn-authorize-date
          :expirationDate="expirationDate"
          :maxAuthorizeDateItem="maxAuthorizeDateItem"
          :chosedAuthCourseIdsLength="chosedAuthCourseIdsLength"
          :submitText="$t('el.common.confirm')"
          @setExpirationDate="setExpirationDate"
          @onHandleAddCourseSubmit="
            (...defaultArg) => updateBatch(0, ...defaultArg)
          "
          @closeDialogCourse="closeEditDialogCourse"
          @onHandleUseDate="onHandleUseDate"
          @onCustomDate="expirationDate = ''"
        >
          <template #appendContent>
            <div
              class="flex flex-m m-auto"
              style="display: flex; align-items: center; margin-top: 12px"
            >
              <span class="color3" style="font-size: 14px"
                >&nbsp;&nbsp;&nbsp;课程试用{{ $t("el.common.colon") }}</span
              >
              <el-checkbox
                v-model="formData.isTry"
                :true-label="1"
                :false-label="0"
                >仅试用（仅可使用前三节课）</el-checkbox
              >
            </div>
          </template>
        </btn-authorize-date>
      </div>
    </c-dialog>
  </div>
</template>

<script>
import CoursePermission from "./components/CoursePermissionNew.vue";
import BtnAuthorizeDate from "./components/BtnAuthorizeDate.vue";
import { mapActions, mapGetters } from "vuex";
import CDialog from "@/components/biz-components/CDialog/index.vue";
import { courseAuthIdToObj } from "@/utils/auth";
import { getRemoteUserListApi, getRoleListApi } from "@/api/manage/role-manage";
export default {
  name: "batchUserPermission",
  // 注册组件
  components: {
    CoursePermission,
    BtnAuthorizeDate,
    CDialog,
  },
  // 定义数据
  data() {
    return {
      confirmLoading: false,
      formData: {
        userIds: [],
        authCourseIds: [],
        userFunctionAuthDto: { upload: 0, download: 0, copy: 0 },
      },
      allLoading: false,
      selectUsers: "",
      chosedAuthCourseIdsLength: 0, // 选中的课程数目
      maxAuthorizeDateItem: {},
      expirationDate: "", // 到期时间
      showEditExpiresDialog: false, //
      optionsEditData: {},
      roleOptions: [], // 角色列表下拉
    };
  },
  // 计算属性
  computed: {
    ...mapGetters(["getAddGradeListData", "staffCode"]),
  },
  // 侦听器
  watch: {},
  // 创建完成钩子
  created() {
    this.formData.userStaffCode = this.staffCode;
    this.formData.userFunctionAuthDto.upload = 0;
    this.formData.userFunctionAuthDto.download = 0;
    this.formData.userFunctionAuthDto.copy = 0;

    this.handleGetRoleList(); // 获取角色下拉列表
  },
  // 挂在完成钩子
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    ...mapActions(["updateBatchUserAuth"]),
    init() {
      this.selectUsers = localStorage.getItem("batchSelectUsersFront");
      this.formData.userIds =
        (localStorage.getItem("batchSelectUserIdsFront") &&
          localStorage.getItem("batchSelectUserIdsFront").split(",")) ||
        [];
    },
    goBack() {
      this.$router.go(-1);
    },
    // 设置最大到期时间-使用
    onHandleUseDate() {
      console.log(4321);
      if (this.maxAuthorizeDateItem.isEditMode) {
        this.expirationDate = this.maxAuthorizeDateItem.maxExpiresDate;
      } else {
        this.expirationDate =
          this.maxAuthorizeDateItem.expiresType === 0
            ? this.maxAuthorizeDateItem.expiresDateDescribe
            : this.maxAuthorizeDateItem.expiresDate;
      }
    },
    //
    batchRemove() {
      const 功能权限number =
        this.formData.userFunctionAuthDto.roleIds.length +
        Number(!this.formData.userFunctionAuthDto.upload) +
        Number(!this.formData.userFunctionAuthDto.download) +
        Number(!this.formData.userFunctionAuthDto.copy);

      if (!功能权限number && !this.chosedAuthCourseIdsLength) {
        this.$message.warning("请至少选择一个功能权限或者课程");
        return;
      }
      this.$alert(
        // this.$t("el.courseList.sureDeleteBatch", [
        //   this.chosedAuthCourseIdsLength,
        // ]),
        `确认要批量删除${功能权限number}个功能权限和${this.chosedAuthCourseIdsLength}个课程权限`,
        this.$t("el.common.reminder"),
        {
          center: true,
          type: "warning",
          confirmButtonText: this.$t("el.common.confirm"),
        }
      ).then(() => {
        this.updateBatch(1);
      });
    },
    // 最大过期时间
    setExpirationDate(expirationDate) {
      this.expirationDate = expirationDate;
    },
    closeEditDialogCourse(refresh) {
      this.showEditExpiresDialog = false;

      if (refresh) {
        // this.coursePermissionType = "default";
        this.chosedAuthCourseIdsLength = 0;
        // 退出编辑模式
        // this.onCancelEdit();
      }
    },
    // 获取最大到期时间的 item 项
    getMaxAuthorizeDate(maxItem, length, isEditMode) {
      maxItem.isEditMode = isEditMode;
      this.maxAuthorizeDateItem = maxItem;
      this.chosedAuthCourseIdsLength = length;
    },
    hanndleUpdateBatch() {
      this.optionsEditData = {
        dialogTitle: this.$t("el.courseList.CourseExpirationTime"),
      };
      this.expirationDate = "";
      console.log(444444);

      if (this.chosedAuthCourseIdsLength === 0) {
        // this.$message.warning(this.$t("el.planCourse.pleaseSelectCourse"));
        // return;
        const 功能权限number =
          this.formData.userFunctionAuthDto.roleIds.length +
          Number(!this.formData.userFunctionAuthDto.upload) +
          Number(!this.formData.userFunctionAuthDto.download) +
          Number(!this.formData.userFunctionAuthDto.copy);

        if (!功能权限number && !this.chosedAuthCourseIdsLength) {
          this.$message.warning("请至少选择一个功能权限或者课程");
          return;
        }
        this.updateBatch(0, null);
      } else {
        this.showEditExpiresDialog = true;
      }
    },
    // 关闭过期时间弹框
    closeEditExpiresDialog() {
      this.showEditExpiresDialog = false;
    },
    // 批量编辑
    async updateBatch(type, value) {
      console.log(type, value, this.formData.isTry);
      this.formData.authCourseIds =
        this.$refs["coursePermission"].getAuthCourseIds();
      //   if (this.formData.authCourseIds.length === 0) {
      //     console.log(555555);
      //     this.$message.error(this.$t("el.planCourse.pleaseSelectCourse"));
      //     return;
      //   }
      if (type === 0) {
        this.formData.expiresType =
          value !== "永久" && this.formData.authCourseIds.length ? 1 : 0; // 必填-到期类型 0：永久，1自定义
        this.formData.expiresDate = value;
        this.formData.type = "addBatch";
        delete this.formData.userStaffCode;
      } else {
        this.formData.type = "deleteBatch";
      }
      try {
        const obj = {
          ...this.formData,
          authOriginCourseIds: this.formData.authCourseIds.map((item) =>
            courseAuthIdToObj(item)
          ),
        };
        // obj.authOriginCourseIds = [
        //   {
        //     authCourseId: "12639",
        //     originTenantType: "4",
        //     originTenantId: "74",
        //     tenantInfoId: "33233",
        //   },
        //   {
        //     authCourseId: "12651",
        //     originTenantType: "4",
        //     originTenantId: "74",
        //     tenantInfoId: "33229",
        //   },
        // ];
        Reflect.deleteProperty(obj, "authCourseIds");
        await this.updateBatchUserAuth(obj);
        if (type === 0) {
          this.$message.success(
            this.$t("el.courseList.BatchPermissionsSucceeded")
          );
          this.$router.go(-1);
        } else {
          this.$message.success(
            this.$t("el.courseList.SuccessfullyDeletedBatch")
          );
          this.$router.go(-1);
        }
      } catch (error) {
        return;
      }
    }, // 获取所有角色下拉
    handleGetRoleList() {
      getRoleListApi().then((res) => {
        this.roleOptions = res?.data || [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.batch-userPermission {
  background: #fff;
  border-radius: 2px;
  padding: 0 20px 60px;
  /deep/ .el-dialog__body {
    padding: 10px 20px 0 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .authorize {
    &-top {
      padding: 18px 16px 0px;
      height: 32px;
      line-height: 32px;
      &-btn {
        width: 90px;
        height: 32px;
        background: rgba(245, 246, 250, 1);
        border-radius: 12px;
        text-align: center;
        margin-right: 28px;
        font-size: 13px;
        // font-family: "MicrosoftYaHei";
        color: rgba(68, 68, 68, 1);
        cursor: pointer;
        img {
          width: 12px;
          margin-right: 4px;
        }
      }
      &-text {
        font-size: 14px;
        // font-family: "MicrosoftYaHei";
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  &-main {
    padding-top: 13px;
    &-item {
      border-bottom: 1px solid #e9e9e9;
      padding-top: 17px;

      // 课程组件区域高度
      /deep/ .checkbox-group-content {
        height: calc(100% - 50px);
        overflow: auto;
      }
      .permission-label {
        width: 100px;
        text-align: right;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
      }
      &-title {
        font-size: 16px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin: 0 0 18px 0;
      }
      &-item {
        padding: 0 0 0px 32px;
        &-label {
          font-size: 14px;
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(121, 123, 127, 1);
          line-height: 20px;
          float: left;
          padding-bottom: 16px;
        }
        &-text {
          font-size: 14px;
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(48, 49, 51, 1);
          line-height: 20px;
          float: left;
          padding-bottom: 16px;
        }
      }
    }
    &-item:last-child {
      border-bottom: 0;
    }
    &-item:first-child {
      padding-top: 0;
      padding-bottom: 16px;
    }
  }
  &-btns {
    text-align: center;
    position: fixed;
    bottom: 40px;
    left: 50%;
    width: fit-content;
    z-index: 1;
    transform: translateX(-50%);
    button {
      // width: 120px;
      height: 40px;

      border-radius: 5px;
      font-size: 16px;
    }
    &-cancel {
      background: rgba(241, 245, 253, 1);
      box-shadow: 0px 2px 4px 0px rgba(68, 129, 251, 0.12);
      border: 1px solid rgba(131, 165, 247, 1);
      color: #4173ee;
    }
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #5a53f5;
    border-color: #5a53f5;
  }

  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #5a53f5;
  }
  .custom-edit-content {
    /deep/ .btn-content {
      position: relative;
      bottom: 0;
      left: 0;
      min-height: 176px;
      transform: none;
    }
  }
}
</style>
